const para1 = {
  overview:
    "Before we elaborate on the things we do and why we do it, we'd like to show you our vision. Our vision is to become global leaders in delivering disruptive business solutions, leveraging cutting-edge technologies, creating products that would positively impact and transform the lives of people.",
};

const para2 = {
  overview:
    "At CodeMyMobile, we all come to work every day, not just to earn our daily bread but for our keen interest towards this profession, there's no better feeling when we deploy a fully functioning flawless app or when we provide, no matter how small, that tad bit of advisory or fully-fledged solution to our clients which has been causing them all sort of restlessness and jitteriness in their chest because they just weren't able to find that piece of jigsaw puzzle and then the smile they give us, the shine in their eyes they get when they finally can see their dream coming true, that bit of a happiness is what we thrive for.",
};

const para3 = {
  overview:
    "Why us? What makes us stand out? It sure is true that just loving a job won't make someone best at it. Not only our developers are best in the market in leveraging cutting-edge technologies and implementing it to the fullest, but our business analysts and expert consultants are also amazingly competent in understanding your point of view and giving you the best advice possible. And this all is followed by a strong agile methodology. So, to drive home an amazing feat, we follow a step by step course of action and stick with you till the very end. As they say, all’s well that ends well.",
};

const para4 = {
  overview:
    'Our CEO Mr Ankit Khatri, an ex-ISRO Space Scientist and an all-time tech enthusiast curated a team of talented developers who thrive in their respective fields to serve the best possible solutions for you, to you.',
};

const para5 = {
  overview:
    'Till now we have engineered the world’s No. 1 Maintenance Software used and recommended by thousands of maintenance professionals and leading companies of the world, the fastest Liquor delivery service in Los Angeles, the best open mic curation app and many more which can be seen in our Portfolio.',
};

const para6 = {
  overview:
    'Sticking to our value structure and vision resulted in a 100% retention rate, which means, our clients stick to us for their new and upcoming projects. Moreover, they are so satisfied by our services that we have got a 90%+ referral rate.',
};

const para7 = {
  overview:
    'Did we mention that till now we’ve been thriving just on organic marketing? Almost all of our clients have contacted us from the word-of-mouth they’ve received.',
};

const AboutUsPage = {
  heading: 'About Us',
  overview: {
    text: [
      "CodeMyMobile is a tightly knitted team of Product Engineers that thrive on entrepreneurial mindset and years of trust. We have 10+ years of experience in building world class ‘customer first tech teams’, these are scalable teams that can glide through from delivering MVPs to huge scale products, teams that entrepreneurs can trust at all times without giving a second thought!",
      "Our CEO Mr Ankit Khatri, an ex-ISRO Space Scientist and an all-time tech enthusiast curated a team of talented developers who thrive in their respective fields to serve the best possible solutions for you, to you.",
      "Our value structure and vision resulted in a 100% retention rate, which means, our clients stick to us for their new and upcoming projects. Moreover, they are so satisfied by our services that we have got a 90%+ referral rate.",
    ],
  },
  about: [para1, para2, para3, para4, para5, para6, para7],
  navigation: {
    prev: {
      title: 'Technology',
      overview:
        'From basics to path-breaking technologies, we hack all of them with sheer precision and excellence. We work on varied platforms and disruptive technologies',
    },
    next: {
      title: 'Industries',
      overview:
        'There is phenomenal digital transformation and emergence of automation in all spheres of business. We have worked across a vast range of industries and cater to the finest of technical expertise required for your business needs.',
    },
  },
  subPage: {
    para1,
    para2,
    para3,
    para4,
    para5,
    para6,
    para7,
  },
};

export default AboutUsPage;
