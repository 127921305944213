import React, { Component } from 'react';
import styled from '@emotion/styled';
import { Layout } from 'layouts';
import { TitleHeader } from '@components';
import headerImage from '@static/header/codemymobile_website_2_blue_white_Aboutus.jpg';
import pageContent from '@constants/aboutUs';

const AboutUsSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    width: 90%;
    flex-direction: column;
    align-items: center;
    margin: auto;
  }
`;

const AboutUsWrapper = styled.div`
  display: flex;
  max-width: 1000px;
  margin: 0 auto;
  position: relative;

  @media (max-width: ${props => props.theme.breakpoints.s}) {
    flex-direction: column;
    align-items: center;
  }
`;

const AboutUsText = styled.p`
  font-size: 16px;
  line-height: 26px;
  color: ${props => props.theme.colors.black.base};
  text-align: center;
`;

class AboutUs extends Component {
  render() {
    return (
      <Layout>
        <TitleHeader image={headerImage} title={pageContent.heading} />

        {this.renderPageAboutUs()}
      </Layout>
    );
  }

  renderPageAboutUs() {
    return pageContent.overview.text.map((aboutUs, idx) => {
      let Paragraphs = aboutUs.split('\n');
      return (
        <AboutUsSection key={idx}>
          <AboutUsWrapper>
            {Paragraphs.map((paragraph, idx) => (
              <AboutUsText key={idx}>{paragraph}</AboutUsText>
            ))}
          </AboutUsWrapper>
        </AboutUsSection>
      );
    });
  }
}

export default AboutUs;
